@font-face {
    font-family: 'Aborm';
    src: url('../fonts/Aborm-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'JUST sans Regular';
    src: url('../fonts/just-sans-regular.otf') format('opentype');
}


body {
    width: 100vw!important;
    height: auto!important;
    min-height: 100vmin!important;
    margin: 0!important;
    padding: 0!important;
}




.main-wrapper {
    position: relative;
    width: 100%!important;
    height: auto!important;
    min-height: 100vmin!important;
    background-color: #000000!important;

    .header-wrapper {
        position: fixed;
        top: 0!important;
        left: 0!important;
        width: 100%!important;
        min-width: 100%!important;
        padding: 60px 10%!important;
        display: flex;
        flex-direction: row;
        background-color: transparent!important;
        z-index: 99999999;

        .brand-name {
            width: auto;
            height: fit-content;

            img {
                height: 80px;
            }
        }

    }

    .content-wrapper {
        position: relative;
        width: 100%!important;
        min-width: 100vw!important;
        height: auto!important;
        min-height: 100vmin!important;
       
        margin: 0!important;
        padding: 0 !important;


        .banner-wrapper {
            width: 100%!important;
            min-width: 100vw!important;
            height: auto!important;
            min-height: 100vmin!important;



            &--banner {
                position: relative;
                width: 100%!important;
                min-height: 100vmin;
                background: url('../img/banner-bg.png');
                background-size: cover;
                background-repeat: no-repeat;
                background-position: top;

                .side-pattern {
                    position: absolute;
                    top: 0!important;
                    right: 0!important;
                    height: 100%!important;
                    
                }

                .banner-content {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%)!important;
                    width: auto!important;

                    .title-white {
                        font-family: 'Aborm';
                        font-size: 3.5rem;
                        font-weight: 400;
                        color: #ffffff;
                        letter-spacing: 2px;
                    }
                    
                    .title-red {
                        font-family: 'Aborm';
                        font-size: 3.5rem;
                        font-weight: 400;
                        color: #DE0000;
                        letter-spacing: 2px;
                    }

                    h1 {
                        font-family: 'JUST sans Regular';
                        font-weight: 400;
                        font-size: 1.5rem;
                        color: #ffffff;
                        text-align: center;
                        letter-spacing: 2px;
                    }
                }
                
                
            }

        }

        .about-wrapper {
            position: relative;
            width: 100%!important;
            min-height: 100vmin;
            background: url('../img/about-bg.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: top;
            padding: 120px 20% 100px 20%!important;
            margin:0!important;

            .about-logo {
                display: block!important;
                height: 100px!important;
                margin: 0 auto;
            }

            p {
                margin-top: 100px!important;
                font-family: 'JUST sans Regular';
                font-weight: 700;
                font-size: 1rem;
                color: #ffffff;
                text-align: center;
            }

        }

        .contact-wrapper {
            position: relative;
            width: 100%!important;
            min-height: 100vmin;
            background-color: #c9cacb!important;
            padding: 120px 20% 50px 20%!important;
            margin: 0!important;

            .title {
                display: block!important;
                margin-bottom: 50px!important;
                font-family: 'JUST sans Regular';
                color: #686868!important;
                font-weight: 700!important;
                font-size: 3rem;
                text-align: center;

            }

            p {
                color: #686868;
                font-family: 'JUST sans Regular';
                font-weight: 400;
                font-size: 1.5rem;
                text-align: center;
                margin-bottom: 50px;
            }

            .form-container {
                width: 100%!important;
                

                

                label {
                    display: block;
                    text-align: left;
                    font-family: 'JUST sans Regular';
                    font-size: 1rem;
                    color: #686868;
                    margin: 20px 0 10px 0!important;
                }

                input[type='text'], input[type='email'] {
                    display: block!important;
                    border-radius: 0!important;
                    border-top: 0!important;
                    border-right: 0!important;
                    border-left: 0!important;
                    border-bottom: 1px solid #686868;
                    background-color: transparent!important;
                    
                }

                textarea {
                    border-radius: 10px;
                    border-color: #686868;
                    background-color: transparent!important;
                }

                .btn-grey {
                    display: block;
                    border: 0!important;
                    background-color: #000000!important;
                    color: #ffffff;
                    border-radius: 50px;
                    padding: 10px 40px!important;
                    margin: 20px auto!important;

                    &:hover {
                        background-color: #DE0000!important;
                        color: #ffffff;
                    }
                }
            }

        }


        
           
       

    }

    .footer-wrapper {
        position: relative;
        width: 100%!important;
        height: auto!important;
        background-color: #000000!important;
        padding: 40px 5%!important;
        color: #ffffff!important;
        display: flex;
        flex-direction: row;

        .logo-container {
            width: 80%!important;
            height: auto;

            .logo {
                height: 60px!important;
            }
        }

        .contact-details {
            width: 20%!important;
            text-align: left;
            color: #ffffff;

            .contact-title {
                display: block;
                font-family: 'JUST sans Regular';
                font-size: 0.9rem;
                font-weight: 700;
                letter-spacing: 1px;
                line-height: 20px;
            }

            .details {
                font-family: 'JUST sans Regular';
                font-size: 0.7rem;
                color: #ffffff;
                display: block;
                line-height: 16px;
            }
        }
    }
}

@media screen and (max-width: 500px) {
   
    
    .main-wrapper {
        position: relative;
        width: 100%!important;
        height: auto!important;
        min-height: 100vmin!important;
        background-color: #000000!important;
        margin: 0!important;

        .header-wrapper {
            position: fixed;
            top: 0!important;
            left: 0!important;
            width: 100%!important;
            min-width: 100%!important;
            padding: 20px 2%!important;
            display: flex;
            flex-direction: row;
            background-color: transparent!important;
            z-index: 99999999;

            .brand-name {
                width: auto;
                height: fit-content;

                img {
                    height: 60px;
                }
            }

        }

        .content-wrapper {
            position: relative;
            width: 100%!important;
            min-width: 100vw!important;
            height: auto!important;
            min-height: 100vmin!important;
        
            margin: 0!important;
            padding: 0 !important;


            .banner-wrapper {
                width: 100%!important;
                min-width: 100vw!important;
                height: auto!important;
                min-height: 100vmin!important;



                &--banner {
                    position: relative;
                    width: 100%!important;
                    min-height: 100vmin;
                    background: url('../img/banner-bg.png');
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: top;

                    .side-pattern {
                        position: absolute;
                        top: 0!important;
                        right: 0!important;
                        height: 100%!important;
                        
                    }

                    .banner-content {
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 100%!important;
                        transform: translate(-50%, -50%)!important;
                        width: auto!important;

                        .title-white {
                            display: block!important;
                            font-family: 'Aborm';
                            font-size: 1.5rem!important;
                            font-weight: 400;
                            color: #ffffff;
                            letter-spacing: 0px;
                            text-align: center;
                            line-height: 10px!important;
                        }
                        
                        .title-red {
                            display: block!important;
                            font-family: 'Aborm';
                            font-size: 1.5rem!important;
                            font-weight: 400;
                            line-height: 5px!important;
                            color: #DE0000;
                            letter-spacing: 0px!important;
                            text-align: center;
                            margin-bottom: 50px!important;
                        }

                        h1 {
                            font-family: 'JUST sans Regular';
                            font-weight: 400;
                            font-size: 0.8rem;
                            color: #ffffff;
                            text-align: center;
                            letter-spacing: 0px;
                        }
                    }
                    
                    
                }

            }

            .about-wrapper {
                position: relative;
                width: 100%!important;
                min-height: 100vmin;
                background: url('../img/about-bg.png');
                background-size: cover;
                background-repeat: no-repeat;
                background-position: top;
                padding: 80px 20% 50px 20%!important;
                margin:0!important;

                .about-logo {
                    display: block!important;
                    height: 100px!important;
                    margin: 0 auto;
                }

                p {
                    margin-top: 100px!important;
                    font-family: 'JUST sans Regular';
                    font-weight: 400;
                    font-size: 0.9rem;
                    color: #ffffff;
                    text-align: center;
                }

            }

            .contact-wrapper {
                position: relative;
                width: 100%!important;
                min-height: 100vmin;
                background-color: #c9cacb!important;
                padding: 80px 20% 50px 20%!important;
                margin: 0!important;

                p {
                    color: #686868;
                    font-family: 'JUST sans Regular';
                    font-weight: 400;
                    font-size: 1rem;
                    text-align: center;
                    margin-bottom: 50px;
                }

                .title {
                    display: block!important;
                    margin-bottom: 30px!important;
                    font-family: 'JUST sans Regular';
                    color: #686868!important;
                    font-weight: 700!important;
                    font-size: 2rem;
                    text-align: center;

                }

                .form-container {
                    width: 100%!important;
                    

                    

                    label {
                        display: block;
                        text-align: left;
                        font-family: 'JUST sans Regular';
                        font-size: 0.8rem;
                        color: #686868;
                        margin: 10px 0 10px 0!important;
                    }

                    input[type='text'], input[type='email'] {
                        display: block!important;
                        border-radius: 0!important;
                        border-top: 0!important;
                        border-right: 0!important;
                        border-left: 0!important;
                        border-bottom: 1px solid #686868;
                        background-color: transparent!important;
                        
                    }

                    textarea {
                        border-radius: 10px;
                        border-color: #686868;
                        background-color: transparent;
                    }

                    .btn-grey {
                        display: block;
                        border: 0!important;
                        background-color: #000000!important;
                        color: #ffffff;
                        border-radius: 50px;
                        padding: 10px 40px!important;
                        margin: 20px auto!important;

                        &:hover {
                            background-color: #DE0000;
                            color: #ffffff;
                        }
                    }
                }

            }


            
            
        

        }

        .footer-wrapper {
            position: relative;
            width: 100%!important;
            height: auto!important;
            background-color: #000000!important;
            padding: 40px 5%!important;
            color: #ffffff!important;
            display: flex;
            flex-direction: column;

            .logo-container {
                width: 100%!important;
                height: auto;
                margin-bottom: 30px!important;

                .logo {
                    display:block!important;
                    margin: 0 auto;
                    height: 60px!important;
                }
            }

            .contact-details {
                width: 100%!important;
                text-align: left;
                color: #ffffff;
                text-align: center!important;

                .contact-title {
                    display: block;
                    font-family: 'JUST sans Regular';
                    font-size: 0.9rem;
                    font-weight: 700;
                    letter-spacing: 1px;
                    line-height: 20px;
                }

                .details {
                    font-family: 'JUST sans Regular';
                    font-size: 0.7rem;
                    color: #ffffff;
                    display: block;
                    line-height: 16px;
                }
            }
        }
    }
}


